import React, { FunctionComponent } from "react";

import { useGameQuery } from "types";

import { Error, Spinner } from "components";

import GameEditForm from "./GameEditForm";

interface Props {
  id: string;
}

const GameEditFormContainer: FunctionComponent<Props> = ({ id }) => {
  const { data, loading, error } = useGameQuery({ variables: { id } });

  if (loading) return <Spinner />;

  if (error || !data?.game) return <Error error={error} />;

  return <GameEditForm game={data.game} />;
};

export default GameEditFormContainer;
