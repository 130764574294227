import React, { FunctionComponent } from "react";

import { useGamesQuery } from "types";

import { Error } from "components";

import GameList from "./GameList";
import GameListSkeleton from "./GameListSkeleton";

const GameListContainer: FunctionComponent = () => {
  const { loading, error, data } = useGamesQuery();

  if (loading) return <GameListSkeleton />;

  if (error || !data) return <Error error={error} />;

  return <GameList data={data} />;
};

export default GameListContainer;
