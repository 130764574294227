import React, { FunctionComponent } from "react";
import moment, { Moment } from "moment";
import { ExecutionResult } from "graphql";

import { Form, Input, Button, DatePicker, message } from "antd";

import { useUpdateGameMutation, Game } from "types";
import { DATE_FORMAT } from "constants/index";

type FormData = {
  title: string;
  description: string;
  time: Moment[];
};

interface Props {
  game: Game;
}

const GameEditForm: FunctionComponent<Props> = ({ game }) => {
  const [updateGameMutation, { loading }] = useUpdateGameMutation();

  const handleSubmitFinish = (response: ExecutionResult) => {
    if (response.errors) {
      message.error({ content: "Something went wrong!", duration: 2 });
    }

    if (response.data) {
      message.success({ content: "Saved!", duration: 2 });
    }
  };

  const handleSubmit = (values: Partial<FormData>) => {
    updateGameMutation({
      variables: {
        id: game.id,
        data: {
          title: values.title,
          description: values.description,
          start: values.time?.[0].valueOf(),
          end: values.time?.[1].valueOf()
        }
      }
    }).then(response => handleSubmitFinish(response));
  };

  const layout = {
    labelCol: { span: 4 },
    wrapperCol: { span: 20 }
  };

  const initialFormData: FormData = {
    title: game.title,
    description: game.description,
    time: [moment(game.start), moment(game.end)]
  };

  return (
    <Form {...layout} initialValues={initialFormData} onFinish={handleSubmit}>
      <Form.Item name={["title"]} label="Title" rules={[{ required: true }]}>
        <Input />
      </Form.Item>
      <Form.Item
        name={["time"]}
        label="Time Range"
        rules={[{ required: true }]}
      >
        <DatePicker.RangePicker
          showTime={{ format: "HH:mm" }}
          format={DATE_FORMAT}
          style={{ width: "100%" }}
        />
      </Form.Item>
      <Form.Item
        name={["description"]}
        label="Description"
        rules={[{ required: true }]}
      >
        <Input.TextArea />
      </Form.Item>
      <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 4 }}>
        <Button type="primary" htmlType="submit" disabled={loading}>
          Submit
        </Button>
      </Form.Item>
    </Form>
  );
};

export default GameEditForm;
