import React, { FunctionComponent } from "react";
import moment from "moment";

import { Card, Divider, Button, Descriptions } from "antd";

import { Game } from "types";
import { DATE_FORMAT } from "constants/index";

interface Props extends Game {}

const GamePreview: FunctionComponent<Props> = ({
  id,
  title,
  description,
  start,
  end
}) => {
  const now = Date.now();

  const actions = [
    start <= now && end >= now && (
      <Button href={`/play/${id}`} type="link">
        Play
      </Button>
    ),
    <Button href={`/game/${id}`} type="link">
      Edit
    </Button>
  ];

  return (
    <Card style={{ marginBottom: "20px" }} title={title} actions={actions}>
      <Descriptions size="small" column={1}>
        <Descriptions.Item label="Game Start">
          {moment(start).format(DATE_FORMAT)}
        </Descriptions.Item>
        <Descriptions.Item label="Game End">
          {moment(end).format(DATE_FORMAT)}
        </Descriptions.Item>
      </Descriptions>

      <Divider dashed />

      <pre>{description}</pre>
    </Card>
  );
};

export default GamePreview;
