import React, { FunctionComponent } from "react";
import { ApolloError } from "apollo-client";

import { Result } from "antd";

interface Props {
  error?: ApolloError;
}

const Error: FunctionComponent<Props> = ({ error }) => {
  const errorMessage = error?.message ?? "Sorry, the server is wrong.";

  return <Result status="500" title="500" subTitle={errorMessage} />;
};

export default Error;
