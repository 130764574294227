import React, { FunctionComponent } from "react";
import { BrowserRouter, Route, Switch, NavLink } from "react-router-dom";
import ReactDOM from "react-dom";
import { ApolloProvider } from "@apollo/react-hooks";

import { Menu, Layout } from "antd";
import { Home, About, NotFound, GameEdit, Play } from "components/@pages";
import { Header, Content } from "components/@ui";

import apolloClient from "config/apolloClient";

import "./index.css";

const App: FunctionComponent = () => {
  return (
    <ApolloProvider client={apolloClient}>
      <BrowserRouter>
        <Layout>
          <Header>
            <Menu mode="horizontal" theme="dark" style={{ lineHeight: "64px" }}>
              <Menu.Item key="home">
                <NavLink to="/">Home</NavLink>
              </Menu.Item>
              <Menu.Item key="about">
                <NavLink to="/about">About</NavLink>
              </Menu.Item>
            </Menu>
          </Header>
          <Content>
            <Switch>
              <Route exact path="/" component={Home}></Route>
              <Route path="/about" component={About}></Route>
              <Route
                path="/game/:id"
                render={props => <GameEdit id={props.match.params.id} />}
              ></Route>
              <Route
                path="/play/:id"
                render={props => <Play id={props.match.params.id} />}
              ></Route>
              <Route component={NotFound}></Route>
            </Switch>
          </Content>
        </Layout>
      </BrowserRouter>
    </ApolloProvider>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
