import React, { FunctionComponent } from "react";

import { Typography } from "antd";

import { Sector as SectorType } from "types";

interface Props extends SectorType {}

const { Text } = Typography;

const Sector: FunctionComponent<Props> = ({ title, answer }) => {
  return (
    <div>
      <Text strong>{`${title} `}</Text>
      {answer ? (
        <>
          <Text>{`${answer.code} `}</Text>
          <Text>@ {new Date(answer.time).toLocaleString()}</Text>
        </>
      ) : (
        <Text>No answer</Text>
      )}
    </div>
  );
};

export default Sector;
