import React, { FunctionComponent, useState, useEffect } from "react";

import { formatDuration } from "utils";

interface Props {
  time: number;
  onFinish?: () => void;
}

const TimeUntil: FunctionComponent<Props> = ({ time, onFinish }) => {
  const [seconds, setSeconds] = useState(
    Math.round((time - Date.now()) / 1000)
  );
  const timer = window.setInterval(() => tick(), 1000);

  useEffect(() => {
    return () => {
      clearInterval(timer);
    };
  }, [timer]);

  const tick = () => {
    setSeconds(Math.max(Math.round((time - Date.now()) / 1000), 0));

    if (seconds <= 0) {
      clearInterval(timer);
      onFinish?.();
    }
  };

  return <>{formatDuration(seconds)}</>;
};

export default TimeUntil;
