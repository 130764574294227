import React, { FunctionComponent } from "react";

import { TimeUntil } from "components";

import { Prompt as PromptType } from "types";

type Props = PromptType & {
  index: number;
  onFinish?: () => void;
};

const Prompt: FunctionComponent<Props> = props => {
  return (
    <div className="prompt">
      <h4>Prompt #{props.index}</h4>
      {props.__typename === "PendingPrompt" && (
        <>
          {`Available after `}
          <TimeUntil time={props.at} onFinish={props.onFinish} />
        </>
      )}
      {props.__typename === "ActivePrompt" && <pre>{props.text}</pre>}
    </div>
  );
};

export default Prompt;
