import React, { FunctionComponent } from "react";
import { Spin } from "antd";

const Spinner: FunctionComponent = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center"
      }}
    >
      <Spin />
    </div>
  );
};

export default Spinner;
