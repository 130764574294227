import React, { FunctionComponent } from "react";

import { usePlayQuery } from "types";

import ActivePlay from "./ActivePlay";
import FinishedPlay from "./FinishedPlay";

interface Props {
  id: string;
}

const PlayContainer: FunctionComponent<Props> = ({ id }) => {
  const { data, loading, error, refetch } = usePlayQuery({ variables: { id } });

  if (loading || error || !data?.play) return null;

  const refreshGame = () => {
    refetch();
  };

  switch (data.play.__typename) {
    case "ActivePlay":
      return <ActivePlay play={data.play} refreshGame={refreshGame} />;
    case "FinishedPlay":
      return <FinishedPlay id={id} refreshGame={refreshGame} />;
    default:
      return null;
  }
};

export default PlayContainer;
