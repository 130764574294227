import React, { FunctionComponent } from "react";

import { TimeUntil } from "components";

interface Props {
  at: number;
  onFinish?: () => void;
}

const Autopass: FunctionComponent<Props> = ({ at, onFinish }) => {
  return (
    <>
      <p>Autopass in {<TimeUntil time={at} onFinish={onFinish}></TimeUntil>}</p>
    </>
  );
};

export default Autopass;
