import React, { FunctionComponent } from "react";

import { Form, Input } from "antd";

import { AnswerType } from "types";

interface Props {
  type: AnswerType;
  onSubmit: (answer: string, type: AnswerType) => Promise<boolean>;
}

const AnswerInput: FunctionComponent<Props> = ({ type, onSubmit }) => {
  const [form] = Form.useForm();

  const submit = ({ answer }: Partial<{ answer: string }>) => {
    if (!answer) return;

    onSubmit(answer, type).then(response => {
      if (response) form.resetFields();
    });
  };

  let label = type === AnswerType.Code ? "Code" : "Bonus";

  const layout = {
    labelCol: { span: 2 },
    wrapperCol: { span: 22 }
  };

  return (
    <Form
      form={form}
      {...layout}
      initialValues={{ answer: "" }}
      onFinish={submit}
    >
      <Form.Item name={["answer"]} label={label}>
        <Input />
      </Form.Item>
    </Form>
  );
};

export default AnswerInput;
