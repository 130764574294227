import React, { FunctionComponent } from "react";

import { Card } from "antd";

const GameListSkeleton: FunctionComponent = () => {
  return (
    <>
      <Card style={{ marginBottom: "20px" }} loading={true} />
      <Card style={{ marginBottom: "20px" }} loading={true} />
      <Card style={{ marginBottom: "20px" }} loading={true} />
    </>
  );
};

export default GameListSkeleton;
