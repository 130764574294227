import gql from 'graphql-tag';
import * as React from 'react';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactComponents from '@apollo/react-components';
import * as ApolloReactHoc from '@apollo/react-hoc';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type ActivePlay = {
   __typename?: 'ActivePlay';
  now: Scalars['Float'];
  game: Game;
  level: Level;
};

export type ActivePrompt = {
   __typename?: 'ActivePrompt';
  text: Scalars['String'];
};

export type Answer = {
   __typename?: 'Answer';
  code: Scalars['String'];
  time: Scalars['Float'];
};

export type FinishedPlay = {
   __typename?: 'FinishedPlay';
  place?: Maybe<Scalars['Float']>;
};

export type Game = {
   __typename?: 'Game';
  id: Scalars['String'];
  title: Scalars['String'];
  description: Scalars['String'];
  start: Scalars['Float'];
  end: Scalars['Float'];
};

export type GameInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  start?: Maybe<Scalars['Float']>;
  end?: Maybe<Scalars['Float']>;
};

export type Level = {
   __typename?: 'Level';
  index: LevelIndex;
  title: Scalars['String'];
  task: Scalars['String'];
  autopass_at?: Maybe<Scalars['Float']>;
  prompts: Array<Prompt>;
  sectors: Array<Sector>;
};

export type LevelIndex = {
   __typename?: 'LevelIndex';
  current: Scalars['Float'];
  total?: Maybe<Scalars['Float']>;
};

export type Mutation = {
   __typename?: 'Mutation';
  createGame?: Maybe<Game>;
  updateGame?: Maybe<Game>;
  removeGame: Scalars['Boolean'];
  restartPlay: Scalars['Boolean'];
  submitAnswer: Scalars['Boolean'];
};


export type MutationCreateGameArgs = {
  input: GameInput;
};


export type MutationUpdateGameArgs = {
  id: Scalars['String'];
  data: GameInput;
};


export type MutationRemoveGameArgs = {
  id: Scalars['String'];
};


export type MutationRestartPlayArgs = {
  game: Scalars['String'];
};


export type MutationSubmitAnswerArgs = {
  game: Scalars['String'];
  answer: Scalars['String'];
  type: Scalars['String'];
};

export type PendingPlay = {
   __typename?: 'PendingPlay';
  now: Scalars['Float'];
  at: Scalars['Float'];
};

export type PendingPrompt = {
   __typename?: 'PendingPrompt';
  at: Scalars['Float'];
};

export type Play = ActivePlay | PendingPlay | FinishedPlay;

export type Prompt = ActivePrompt | PendingPrompt;

export type Query = {
   __typename?: 'Query';
  game?: Maybe<Game>;
  games: Array<Game>;
  play?: Maybe<Play>;
};


export type QueryGameArgs = {
  id: Scalars['String'];
};


export type QueryPlayArgs = {
  game: Scalars['String'];
};

export type Sector = {
   __typename?: 'Sector';
  title: Scalars['String'];
  answer?: Maybe<Answer>;
};

export type GameQueryVariables = {
  id: Scalars['String'];
};


export type GameQuery = (
  { __typename?: 'Query' }
  & { game?: Maybe<(
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'title' | 'description' | 'start' | 'end'>
  )> }
);

export type UpdateGameMutationVariables = {
  id: Scalars['String'];
  data: GameInput;
};


export type UpdateGameMutation = (
  { __typename?: 'Mutation' }
  & { updateGame?: Maybe<(
    { __typename?: 'Game' }
    & Pick<Game, 'id'>
  )> }
);

export type GamesQueryVariables = {};


export type GamesQuery = (
  { __typename?: 'Query' }
  & { games: Array<(
    { __typename?: 'Game' }
    & Pick<Game, 'id' | 'title' | 'description' | 'start' | 'end'>
  )> }
);

export type PlayQueryVariables = {
  id: Scalars['String'];
};


export type PlayQuery = (
  { __typename?: 'Query' }
  & { play?: Maybe<(
    { __typename: 'ActivePlay' }
    & Pick<ActivePlay, 'now'>
    & { game: (
      { __typename?: 'Game' }
      & Pick<Game, 'id' | 'title' | 'description' | 'start' | 'end'>
    ), level: (
      { __typename?: 'Level' }
      & Pick<Level, 'title' | 'task' | 'autopass_at'>
      & { index: (
        { __typename?: 'LevelIndex' }
        & Pick<LevelIndex, 'current' | 'total'>
      ), prompts: Array<(
        { __typename: 'ActivePrompt' }
        & Pick<ActivePrompt, 'text'>
      ) | (
        { __typename: 'PendingPrompt' }
        & Pick<PendingPrompt, 'at'>
      )>, sectors: Array<(
        { __typename?: 'Sector' }
        & Pick<Sector, 'title'>
        & { answer?: Maybe<(
          { __typename?: 'Answer' }
          & Pick<Answer, 'code' | 'time'>
        )> }
      )> }
    ) }
  ) | (
    { __typename: 'PendingPlay' }
    & Pick<PendingPlay, 'now' | 'at'>
  ) | (
    { __typename: 'FinishedPlay' }
    & Pick<FinishedPlay, 'place'>
  )> }
);

export type RestartPlayMutationVariables = {
  id: Scalars['String'];
};


export type RestartPlayMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'restartPlay'>
);

export type SubmitAnswerMutationVariables = {
  id: Scalars['String'];
  answer: Scalars['String'];
  type: Scalars['String'];
};


export type SubmitAnswerMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'submitAnswer'>
);


export const GameDocument = gql`
    query Game($id: String!) {
  game(id: $id) {
    id
    title
    description
    start
    end
  }
}
    `;
export type GameComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GameQuery, GameQueryVariables>, 'query'> & ({ variables: GameQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const GameComponent = (props: GameComponentProps) => (
      <ApolloReactComponents.Query<GameQuery, GameQueryVariables> query={GameDocument} {...props} />
    );
    
export type GameProps<TChildProps = {}> = ApolloReactHoc.DataProps<GameQuery, GameQueryVariables> & TChildProps;
export function withGame<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GameQuery,
  GameQueryVariables,
  GameProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GameQuery, GameQueryVariables, GameProps<TChildProps>>(GameDocument, {
      alias: 'game',
      ...operationOptions
    });
};

/**
 * __useGameQuery__
 *
 * To run a query within a React component, call `useGameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGameQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGameQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGameQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GameQuery, GameQueryVariables>) {
        return ApolloReactHooks.useQuery<GameQuery, GameQueryVariables>(GameDocument, baseOptions);
      }
export function useGameLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GameQuery, GameQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GameQuery, GameQueryVariables>(GameDocument, baseOptions);
        }
export type GameQueryHookResult = ReturnType<typeof useGameQuery>;
export type GameLazyQueryHookResult = ReturnType<typeof useGameLazyQuery>;
export type GameQueryResult = ApolloReactCommon.QueryResult<GameQuery, GameQueryVariables>;
export const UpdateGameDocument = gql`
    mutation UpdateGame($id: String!, $data: GameInput!) {
  updateGame(id: $id, data: $data) {
    id
  }
}
    `;
export type UpdateGameMutationFn = ApolloReactCommon.MutationFunction<UpdateGameMutation, UpdateGameMutationVariables>;
export type UpdateGameComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<UpdateGameMutation, UpdateGameMutationVariables>, 'mutation'>;

    export const UpdateGameComponent = (props: UpdateGameComponentProps) => (
      <ApolloReactComponents.Mutation<UpdateGameMutation, UpdateGameMutationVariables> mutation={UpdateGameDocument} {...props} />
    );
    
export type UpdateGameProps<TChildProps = {}> = ApolloReactHoc.MutateProps<UpdateGameMutation, UpdateGameMutationVariables> & TChildProps;
export function withUpdateGame<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  UpdateGameMutation,
  UpdateGameMutationVariables,
  UpdateGameProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, UpdateGameMutation, UpdateGameMutationVariables, UpdateGameProps<TChildProps>>(UpdateGameDocument, {
      alias: 'updateGame',
      ...operationOptions
    });
};

/**
 * __useUpdateGameMutation__
 *
 * To run a mutation, you first call `useUpdateGameMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateGameMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateGameMutation, { data, loading, error }] = useUpdateGameMutation({
 *   variables: {
 *      id: // value for 'id'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateGameMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateGameMutation, UpdateGameMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateGameMutation, UpdateGameMutationVariables>(UpdateGameDocument, baseOptions);
      }
export type UpdateGameMutationHookResult = ReturnType<typeof useUpdateGameMutation>;
export type UpdateGameMutationResult = ApolloReactCommon.MutationResult<UpdateGameMutation>;
export type UpdateGameMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateGameMutation, UpdateGameMutationVariables>;
export const GamesDocument = gql`
    query Games {
  games {
    id
    title
    description
    start
    end
  }
}
    `;
export type GamesComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<GamesQuery, GamesQueryVariables>, 'query'>;

    export const GamesComponent = (props: GamesComponentProps) => (
      <ApolloReactComponents.Query<GamesQuery, GamesQueryVariables> query={GamesDocument} {...props} />
    );
    
export type GamesProps<TChildProps = {}> = ApolloReactHoc.DataProps<GamesQuery, GamesQueryVariables> & TChildProps;
export function withGames<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  GamesQuery,
  GamesQueryVariables,
  GamesProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, GamesQuery, GamesQueryVariables, GamesProps<TChildProps>>(GamesDocument, {
      alias: 'games',
      ...operationOptions
    });
};

/**
 * __useGamesQuery__
 *
 * To run a query within a React component, call `useGamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGamesQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGamesQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GamesQuery, GamesQueryVariables>) {
        return ApolloReactHooks.useQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
      }
export function useGamesLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GamesQuery, GamesQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GamesQuery, GamesQueryVariables>(GamesDocument, baseOptions);
        }
export type GamesQueryHookResult = ReturnType<typeof useGamesQuery>;
export type GamesLazyQueryHookResult = ReturnType<typeof useGamesLazyQuery>;
export type GamesQueryResult = ApolloReactCommon.QueryResult<GamesQuery, GamesQueryVariables>;
export const PlayDocument = gql`
    query Play($id: String!) {
  play(game: $id) {
    __typename
    ... on ActivePlay {
      now
      game {
        id
        title
        description
        start
        end
      }
      level {
        index {
          current
          total
        }
        title
        task
        autopass_at
        prompts {
          __typename
          ... on ActivePrompt {
            text
          }
          ... on PendingPrompt {
            at
          }
        }
        sectors {
          title
          answer {
            code
            time
          }
        }
      }
    }
    ... on PendingPlay {
      now
      at
    }
    ... on FinishedPlay {
      place
    }
  }
}
    `;
export type PlayComponentProps = Omit<ApolloReactComponents.QueryComponentOptions<PlayQuery, PlayQueryVariables>, 'query'> & ({ variables: PlayQueryVariables; skip?: boolean; } | { skip: boolean; });

    export const PlayComponent = (props: PlayComponentProps) => (
      <ApolloReactComponents.Query<PlayQuery, PlayQueryVariables> query={PlayDocument} {...props} />
    );
    
export type PlayProps<TChildProps = {}> = ApolloReactHoc.DataProps<PlayQuery, PlayQueryVariables> & TChildProps;
export function withPlay<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  PlayQuery,
  PlayQueryVariables,
  PlayProps<TChildProps>>) {
    return ApolloReactHoc.withQuery<TProps, PlayQuery, PlayQueryVariables, PlayProps<TChildProps>>(PlayDocument, {
      alias: 'play',
      ...operationOptions
    });
};

/**
 * __usePlayQuery__
 *
 * To run a query within a React component, call `usePlayQuery` and pass it any options that fit your needs.
 * When your component renders, `usePlayQuery` returns an object from Apollo Client that contains loading, error, and data properties 
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePlayQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function usePlayQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<PlayQuery, PlayQueryVariables>) {
        return ApolloReactHooks.useQuery<PlayQuery, PlayQueryVariables>(PlayDocument, baseOptions);
      }
export function usePlayLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<PlayQuery, PlayQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<PlayQuery, PlayQueryVariables>(PlayDocument, baseOptions);
        }
export type PlayQueryHookResult = ReturnType<typeof usePlayQuery>;
export type PlayLazyQueryHookResult = ReturnType<typeof usePlayLazyQuery>;
export type PlayQueryResult = ApolloReactCommon.QueryResult<PlayQuery, PlayQueryVariables>;
export const RestartPlayDocument = gql`
    mutation RestartPlay($id: String!) {
  restartPlay(game: $id)
}
    `;
export type RestartPlayMutationFn = ApolloReactCommon.MutationFunction<RestartPlayMutation, RestartPlayMutationVariables>;
export type RestartPlayComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<RestartPlayMutation, RestartPlayMutationVariables>, 'mutation'>;

    export const RestartPlayComponent = (props: RestartPlayComponentProps) => (
      <ApolloReactComponents.Mutation<RestartPlayMutation, RestartPlayMutationVariables> mutation={RestartPlayDocument} {...props} />
    );
    
export type RestartPlayProps<TChildProps = {}> = ApolloReactHoc.MutateProps<RestartPlayMutation, RestartPlayMutationVariables> & TChildProps;
export function withRestartPlay<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  RestartPlayMutation,
  RestartPlayMutationVariables,
  RestartPlayProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, RestartPlayMutation, RestartPlayMutationVariables, RestartPlayProps<TChildProps>>(RestartPlayDocument, {
      alias: 'restartPlay',
      ...operationOptions
    });
};

/**
 * __useRestartPlayMutation__
 *
 * To run a mutation, you first call `useRestartPlayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRestartPlayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [restartPlayMutation, { data, loading, error }] = useRestartPlayMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRestartPlayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<RestartPlayMutation, RestartPlayMutationVariables>) {
        return ApolloReactHooks.useMutation<RestartPlayMutation, RestartPlayMutationVariables>(RestartPlayDocument, baseOptions);
      }
export type RestartPlayMutationHookResult = ReturnType<typeof useRestartPlayMutation>;
export type RestartPlayMutationResult = ApolloReactCommon.MutationResult<RestartPlayMutation>;
export type RestartPlayMutationOptions = ApolloReactCommon.BaseMutationOptions<RestartPlayMutation, RestartPlayMutationVariables>;
export const SubmitAnswerDocument = gql`
    mutation SubmitAnswer($id: String!, $answer: String!, $type: String!) {
  submitAnswer(game: $id, answer: $answer, type: $type)
}
    `;
export type SubmitAnswerMutationFn = ApolloReactCommon.MutationFunction<SubmitAnswerMutation, SubmitAnswerMutationVariables>;
export type SubmitAnswerComponentProps = Omit<ApolloReactComponents.MutationComponentOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>, 'mutation'>;

    export const SubmitAnswerComponent = (props: SubmitAnswerComponentProps) => (
      <ApolloReactComponents.Mutation<SubmitAnswerMutation, SubmitAnswerMutationVariables> mutation={SubmitAnswerDocument} {...props} />
    );
    
export type SubmitAnswerProps<TChildProps = {}> = ApolloReactHoc.MutateProps<SubmitAnswerMutation, SubmitAnswerMutationVariables> & TChildProps;
export function withSubmitAnswer<TProps, TChildProps = {}>(operationOptions?: ApolloReactHoc.OperationOption<
  TProps,
  SubmitAnswerMutation,
  SubmitAnswerMutationVariables,
  SubmitAnswerProps<TChildProps>>) {
    return ApolloReactHoc.withMutation<TProps, SubmitAnswerMutation, SubmitAnswerMutationVariables, SubmitAnswerProps<TChildProps>>(SubmitAnswerDocument, {
      alias: 'submitAnswer',
      ...operationOptions
    });
};

/**
 * __useSubmitAnswerMutation__
 *
 * To run a mutation, you first call `useSubmitAnswerMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSubmitAnswerMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [submitAnswerMutation, { data, loading, error }] = useSubmitAnswerMutation({
 *   variables: {
 *      id: // value for 'id'
 *      answer: // value for 'answer'
 *      type: // value for 'type'
 *   },
 * });
 */
export function useSubmitAnswerMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>) {
        return ApolloReactHooks.useMutation<SubmitAnswerMutation, SubmitAnswerMutationVariables>(SubmitAnswerDocument, baseOptions);
      }
export type SubmitAnswerMutationHookResult = ReturnType<typeof useSubmitAnswerMutation>;
export type SubmitAnswerMutationResult = ApolloReactCommon.MutationResult<SubmitAnswerMutation>;
export type SubmitAnswerMutationOptions = ApolloReactCommon.BaseMutationOptions<SubmitAnswerMutation, SubmitAnswerMutationVariables>;