import React, { FunctionComponent } from "react";

import { Play as PlayContainer } from "components";

interface Props {
  id: string;
}

const Play: FunctionComponent<Props> = ({ id }) => {
  return <PlayContainer id={id} />;
};

export default Play;
