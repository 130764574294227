import React, { FunctionComponent } from "react";

import { Button } from "antd";

import { useRestartPlayMutation } from "types";

interface Props {
  id: string;
  refreshGame: () => void;
}

const FinishedPlay: FunctionComponent<Props> = ({ id, refreshGame }) => {
  const [restartPlayMutation] = useRestartPlayMutation();

  const restartGame = () => {
    restartPlayMutation({ variables: { id } }).then(refreshGame);
  };

  return (
    <>
      <h4>The Game is finished</h4>
      <Button type="primary" onClick={restartGame}>
        Restart
      </Button>
    </>
  );
};

export default FinishedPlay;
