import React, { FunctionComponent, ReactNode } from "react";
import { Row, Col, Layout } from "antd";

interface Props {
  children?: ReactNode;
}

const { Content: AntContent } = Layout;

const Content: FunctionComponent<Props> = ({ children }) => {
  return (
    <AntContent>
      <Row>
        <Col span={12} offset={6}>
          <div style={{ padding: "20px 0" }}>{children}</div>
        </Col>
      </Row>
    </AntContent>
  );
};

export default Content;
