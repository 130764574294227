import React, { FunctionComponent } from "react";

import { GamePreview } from "components";

import { GamesQuery } from "types";

interface Props {
  data: GamesQuery;
}

const GameList: FunctionComponent<Props> = ({ data: { games } }) => {
  return (
    <>
      {games?.map(game => (
        <GamePreview key={game.id} {...game} />
      ))}
    </>
  );
};

export default GameList;
