export function formatDuration(seconds: number) {
  let minutes = Math.floor(seconds / 60);
  seconds %= 60;

  let hours = Math.floor(minutes / 60);
  minutes %= 60;

  let days = Math.floor(hours / 24);
  hours %= 24;

  let parts = [];
  if (days) {
    parts.push(days, days === 1 ? " day " : " days ");
  }
  if (hours) {
    parts.push(hours, hours === 1 ? " hour " : " hours ");
  }
  if (minutes) {
    parts.push(minutes, minutes === 1 ? " minute " : " minutes ");
  }
  if (seconds || parts.length === 0) {
    parts.push(seconds, seconds === 1 ? " second " : " seconds ");
  }

  return parts;
}
