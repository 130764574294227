import React, { FunctionComponent, ReactNode } from "react";
import { Row, Col, Layout } from "antd";

interface Props {
  children?: ReactNode;
}

const { Header: AntHeader } = Layout;

const Header: FunctionComponent<Props> = ({ children }) => {
  return (
    <AntHeader style={{ padding: 0 }}>
      <Row>
        <Col span={12} offset={6}>
          {children}
        </Col>
      </Row>
    </AntHeader>
  );
};

export default Header;
