import React, { FunctionComponent } from "react";

import { GameEditForm } from "components";

interface Props {
  id: string;
}

const GameEdit: FunctionComponent<Props> = ({ id }) => {
  return <GameEditForm id={id} />;
};

export default GameEdit;
