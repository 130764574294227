import React, { FunctionComponent } from "react";

import { AnswerInput, Autopass, Sector, Prompt } from "components";

import {
  useSubmitAnswerMutation,
  AnswerType,
  ActivePlay as ActivePlayType
} from "types";

interface Props {
  play: ActivePlayType;
  refreshGame: () => void;
}

const ActivePlay: FunctionComponent<Props> = ({ play, refreshGame }) => {
  const [submitAnswerMutation] = useSubmitAnswerMutation();

  const submitAnswer = async (code: string, type: AnswerType) => {
    return submitAnswerMutation({
      variables: {
        id: play.game.id,
        answer: code,
        type
      }
    }).then(response => !!response.data?.submitAnswer);
  };

  const totalIndex = play.level.index.total ?? "?";

  return (
    <>
      <h2>{play.game.title}</h2>
      <AnswerInput type={AnswerType.Code} onSubmit={submitAnswer} />
      <AnswerInput type={AnswerType.Bonus} onSubmit={submitAnswer} />
      <h3>{`[${play.level.index.current}/${totalIndex}] ${play.level.title}`}</h3>
      {play.level.autopass_at && (
        <Autopass at={play.level.autopass_at} onFinish={refreshGame} />
      )}
      {play.level.sectors.map((sector, index) => (
        <Sector key={index} {...sector} />
      ))}
      <pre>{play.level.task}</pre>
      {play.level.prompts.map((prompt, index) => (
        <Prompt
          key={index}
          index={index + 1}
          {...prompt}
          onFinish={refreshGame}
        />
      ))}
    </>
  );
};

export default ActivePlay;
